<template>
  <v-card
    class="card--coin-product"
    :class="{ clickable: clickable, selected: selectedProduct === product }"
    v-if="product"
    @click="selectProduct"
  >
    <div class="top--part">
      <div class="bg--fill">
        <div class="fill-1"></div>
        <div class="fill-2"></div>
      </div>
      <div class="amount--text">
        <div>
          <div class="top--amount">
            {{ product.qty }}
          </div>
          <div class="bottom--amount">
            {{ $t('product.coinSlot') }}
          </div>
        </div>
      </div>
    </div>
    <div class="bottom--part">
      <div v-if="product.discount_price">
        <div class="price--before">
          {{ $n(product.price, 'currency', 'id-ID') }}
        </div>
        <div class="price price--after">
          {{ $n(product.price - product.discount_price, 'currency', 'id-ID') }}
        </div>
      </div>
      <div v-else>
        <div class="price">{{ $n(product.price, 'currency', 'id-ID') }}</div>
      </div>
      <div class="bonus" v-if="product.bonus_qty">
        {{ `Free ${product.bonus_qty} koin` }}
      </div>
      <div class="duration">
        {{ `${product.duration} hari` }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    product: {
      default: null,
      require: true,
    },
    clickable: {
      default: false,
    },
    showDesc: {
      default: true,
    },
  },
  data: () => ({}),
  computed: {
    selectedProduct: {
      get() {
        return this.$store.state.product.selectedProduct;
      },
      set(val) {
        this.$store.commit('product/SET_SELECTED_PRODUCT', val);
      },
    },
  },
  methods: {
    selectProduct() {
      this.selectedProduct = this.product;
    },
  },
};
</script>
