<template>
  <div class="listing--availability-option" v-if="activeTab === 'tab-listing-availability'">
    <div class="container">
      <v-card class="listing--availability-promo">
        <div style="margin-bottom: 12px">{{ $t('product.listingAvailability.promo1') }}</div>
        <div style="margin-bottom: 8px">{{ $t('product.listingAvailability.promo2') }}</div>
        <button
          type="button"
          class="btn btn--whatsapp"
          style="margin-left: auto; margin-right: auto; margin-top: 8px"
          @click="handleContact"
        >
          <img
            src="@/assets/img/icons/whatsapp-1.svg"
            alt="Phone"
            style="margin-right: 4px; width: 24px"
          />
          <span style="font-family: Poppins-SemiBold">{{ $t('general.contactUs') }}</span>
        </button>
      </v-card>
      <div class="listing--availability-desc">{{ $t('product.listingAvailability.qty') }}</div>
      <div class="listing--availability-select">
        <v-text-field
          :label="$t('product.listingAvailability.qtyPlc')"
          outlined
          :hide-details="true"
          v-model="selectedQty"
          name="selectedQty"
          class="basic--input listing--availability-input"
          type="number"
          step="10"
        ></v-text-field>
      </div>
      <div
        class="d-flex justify-center"
        style="margin-top: -28px; margin-bottom: 28px"
        v-if="validation.hasError('selectedQty')"
      >
        <span class="val-error">{{ validation.firstError('selectedQty') }}</span>
      </div>
      <div class="listing--availability-desc">{{ $t('product.listingAvailability.duration') }}</div>
      <div class="listing--availability-select">
        <multiselect
          id="selectedDuration"
          v-model="selectedDuration"
          :options="selectedDurationList"
          :close-on-select="true"
          track-by="id"
          label="name"
          select-label=""
          selected-label=""
          deselect-label=""
          :allowEmpty="false"
          :placeholder="$t('product.listingAvailability.durationPlc')"
          style="max-width: 300px"
          :class="{ 'has-error': validation.hasError('selectedDuration') }"
          class="listing--availability-input"
        >
        </multiselect>
      </div>
      <div
        class="d-flex justify-center"
        style="margin-top: -28px; margin-bottom: 28px"
        v-if="validation.hasError('selectedDuration')"
      >
        <span class="val-error">{{ validation.firstError('selectedDuration') }}</span>
      </div>
      <v-card class="listing--availability-content" v-if="totalPrice">
        <div class="content--title">{{ $t('product.listingAvailability.summary') }}</div>
        <div class="content--row">
          <div>{{ $t('product.listingAvailability.expiredAt') }}</div>
          <div class="content--expired">
            {{ expiredAt }}
          </div>
        </div>
        <div class="content--row">
          <div>{{ $t('product.listingAvailability.price') }}</div>
          <div class="content--price">
            {{ totalPrice }}
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'listing-availability-option',
  mixins: [HelperMixin],
  components: { Multiselect },
  props: ['activeTab'],
  data: () => ({
    selectedDurationList: [
      {
        id: 1,
        name: '30 hari (~1 bulan)',
        value: 30,
      },
      {
        id: 2,
        name: '60 hari (~2 bulan)',
        value: 60,
      },
      {
        id: 3,
        name: '90 hari (~3 bulan)',
        value: 90,
      },
      {
        id: 4,
        name: '120 hari (~4 bulan)',
        value: 120,
      },
      {
        id: 5,
        name: '150 hari (~5 bulan)',
        value: 150,
      },
      {
        id: 6,
        name: '180 hari (~6 bulan)',
        value: 180,
      },
      {
        id: 7,
        name: '210 hari (~7 bulan)',
        value: 210,
      },
      {
        id: 8,
        name: '240 hari (~8 bulan)',
        value: 240,
      },
      {
        id: 9,
        name: '270 hari (~9 bulan)',
        value: 270,
      },
      {
        id: 10,
        name: '300 hari (~10 bulan)',
        value: 300,
      },
      {
        id: 11,
        name: '330 hari (~11 bulan)',
        value: 330,
      },
      {
        id: 12,
        name: '360 hari (~1 tahun)',
        value: 360,
      },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      selectedProduct: (state) => state.product.selectedProduct,
    }),
    expiredAt() {
      if (this.selectedQty && this.selectedDuration && this.selectedProduct) {
        if (this.selectedQty >= 20 && this.selectedQty % 10 === 0) {
          let expiredAt = new Date();
          expiredAt.setDate(expiredAt.getDate() + this.selectedDuration.value);
          return this.$date.format(expiredAt, 'dd MMMM yyyy');
        }
      }
      return null;
    },
    totalPrice() {
      if (this.selectedQty && this.selectedDuration && this.selectedProduct) {
        if (this.selectedQty >= 20 && this.selectedQty % 10 === 0) {
          let price = this.selectedProduct.price;
          price *= this.selectedQty / this.selectedProduct.qty;
          price *= this.selectedDuration.value / this.selectedProduct.duration;
          return this.$n(price, 'currency', 'id-ID');
        }
      }
      return null;
    },
    selectedQty: {
      get() {
        return this.$store.state.product.selectedQty;
      },
      set(value) {
        this.$store.commit('product/SET_SELECTED_QTY', value);
      },
    },
    selectedDuration: {
      get() {
        return this.$store.state.product.selectedDuration;
      },
      set(value) {
        this.$store.commit('product/SET_SELECTED_DURATION', value);
      },
    },
  },
  validators: {
    selectedQty(value) {
      if (this.activeTab === 'tab-listing-availability') {
        return Validator.value(value)
          .required(this.$i18n.t('errors.selectedQty.required'))
          .greaterThanOrEqualTo(20, this.$i18n.t('errors.selectedQty.min', { min: 20 }))
          .custom(() => {
            if (value % 10 !== 0) {
              return this.$i18n.t('errors.selectedQty.step', { step: 10 });
            }
          });
      } else {
        return Validator.value(value);
      }
    },
    selectedDuration(value) {
      if (this.activeTab === 'tab-listing-availability') {
        return Validator.value(value).required(this.$i18n.t('errors.selectedDuration.required'));
      } else {
        return Validator.value(value);
      }
    },
  },
  mounted() {
    this.selectedQty = null;
    this.selectedDuration = null;
  },
  methods: {
    async validate() {
      return this.$validate();
    },
    handleContact() {
      let url = '';
      const contactNumber = '+' + this.$t('general.phone2');
      const text = 'Halo, saya mau menanyakan mengenai kode promo pembelian slot listing.';
      url = `https://wa.me/${contactNumber}?text=${text}`;

      window.open(url, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';

.listing--availability-option {
  .container {
    max-width: 600px;
    margin: 0 auto;
    @media #{$phones} {
      margin-top: 18px;
    }
  }

  .listing--availability-promo {
    text-align: center;
    margin-bottom: 32px;
    font-size: $font-md;
    padding: 24px;
    border-radius: 16px !important;
    background-color: $color-warning-light;
  }

  .listing--availability-desc {
    text-align: center;
    margin-bottom: 12px;
  }

  .listing--availability-select {
    display: flex;
    justify-content: center;
    margin-bottom: 32px;
    .listing--availability-input {
      width: 280px;
      max-width: 280px;
    }
  }

  .listing--availability-content {
    text-align: center;
    margin-bottom: 12px;
    padding: 12px 24px;
    border-radius: 16px !important;

    .content--title {
      font-family: Poppins-SemiBold;
      font-size: $font-ml;
      color: $color-beliruma-1;
      margin-bottom: 8px;
    }

    .content--row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: solid 1px $color-gray-1;

      &:last-child {
        border-bottom: none;
      }

      & > div {
        color: $color-text-black;
        &:last-child {
          text-align: right;
        }
      }

      .content--expired {
        font-family: Poppins-SemiBold;
      }

      .content--price {
        font-family: Poppins-SemiBold;
        color: $color-beliruma-1;
      }
    }
  }
}
</style>
