<template>
  <div class="product--tos">
    <div class="container">
      <div class="tos--title">
        <span v-if="activeTab === 'tab-coin'">{{ $t('product.coin.tos') }}</span>
        <span v-else>{{ $t('product.account.tos') }}</span>
      </div>
      <ol v-if="activeTab === 'tab-coin'">
        <li>
          <div class="tos--line">{{ $t('product.coin.line1') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.coin.line2') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.coin.line3') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.coin.line4') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.coin.line5') }}</div>
        </li>
      </ol>
      <ol v-else>
        <li>
          <div class="tos--line">{{ $t('product.account.line1') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.account.line2') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.account.line3') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.account.line4') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.account.line5') }}</div>
        </li>
        <li>
          <div class="tos--line">{{ $t('product.account.line6') }}</div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  name: 'product-button',
  props: ['activeTab'],
  computed: {},
  methods: {
    async openCheckout() {
      try {
        let data = await this.$store.dispatch('checkout/getCheckoutInfo', {
          product: this.selectedProduct,
        });
        if (data !== null) {
          this.$router.push({
            path: `/checkout`,
          });
        }
      } catch (e) {
        console.log('ERROR CHECKOUT PRODUCT: ', e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.product--tos {
  background-color: $color-beliruma-1-light;
  .container {
    max-width: 800px;
    margin: 36px auto 0 auto;
    @media #{$phones} {
      margin-top: 18px;
    }
  }
  .tos--title {
    font-family: Poppins-SemiBold;
    font-size: $font-ml;
    color: $color-text-black;
    margin-bottom: 12px;
    @media #{$phones} {
      font-size: $font-md;
      margin-bottom: 8px;
    }
  }
  .tos--line {
    color: $color-text-black;
    margin-bottom: 4px;
    @media #{$phones} {
      font-size: $font-sm;
      margin-bottom: 0;
    }
  }
}
</style>
