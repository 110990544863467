<template>
  <v-card
    class="card--account-product"
    :class="{
      clickable: clickable,
      selected: selectedProduct === product,
    }"
    v-if="product"
    @click="selectProduct"
  >
    <div
      class="title--part"
      :style="{
        'background-color': `#${
          product.membership.color_code ? product.membership.color_code : 'FFFFFF'
        }`,
      }"
      :class="{
        'bordered--title-part':
          product.membership.color_code === null || product.membership.color_code === 'FFFFFF',
      }"
    >
      <div
        class="title--text"
        :class="{
          'dark--text':
            product.membership.color_code === null || product.membership.color_code === 'FFFFFF',
        }"
      >
        <span>{{ product.name }}</span>
        <img
          :src="product.membership.logo"
          class="ml-2"
          v-if="product.membership && product.membership.logo"
        />
      </div>
    </div>
    <div class="top--part">
      <!--      <div class="title&#45;&#45;text">-->
      <!--        <span>{{ product.name }}</span>-->
      <!--        <img-->
      <!--                :src="product.membership.logo"-->
      <!--                class="ml-2"-->
      <!--                v-if="product.membership && product.membership.logo"-->
      <!--        />-->
      <!--      </div>-->
      <div v-if="product.discount && product.discount > 0">
        <div class="price--before">
          {{ $n(product.price, 'currency', 'id-ID') }}
        </div>
        <div class="price">
          {{ $n(product.price - product.discount, 'currency', 'id-ID') }}
        </div>
      </div>
      <div v-else>
        <div class="price--before" v-if="product.original_price">
          {{ $n(product.original_price, 'currency', 'id-ID') }}
        </div>
        <div class="price">
          {{ product.price ? $n(product.price, 'currency', 'id-ID') : $t('general.free') }}
        </div>
      </div>
      <div class="duration">
        {{ `${product.membership.duration} hari` }}
      </div>
      <div class="d-flex align-center">
        <!--        <img src="@/assets/img/icons/google-logo.png" alt="Google" class="mr-2" />-->
      </div>
    </div>
    <div class="bottom--part-margin"></div>
    <div class="bottom--part">
      <!--      <div class="label&#45;&#45;container" v-if="product.item_type === 'SHOP'">-->
      <!--        <div class="label">{{ $t('adsBoost.shop') }}</div>-->
      <!--      </div>-->
      <div class="d-flex justify-space-between align-center">
        <div class="product--desc">{{ $t('product.listingSlot') }}</div>
        <div class="product--type">
          {{ product.available_listing ? product.available_listing.amount : 0 }}
        </div>
      </div>
      <div class="d-flex justify-space-between align-center">
        <div class="product--desc">
          {{ $t('product.coinSlot') }}
          <span v-if="product.coins.referral_bonus_amount">
            {{ `(+${product.coins.referral_bonus_amount} ${$t('product.referralBonus')})` }}</span
          >
        </div>
        <div class="product--type" v-if="product.coins">
          <span v-if="product.coins.amount !== product.coins.original_amount">
            <span class="coins--before" v-if="product.coins.original_amount">{{
              product.coins.original_amount
            }}</span>
            <span class="coins--before" v-else-if="product.coins.referral_bonus_amount">{{
              product.coins.amount
            }}</span>
          </span>
          <span v-if="product.coins.referral_bonus_amount">{{
            product.coins.amount + product.coins.referral_bonus_amount
          }}</span>
          <span v-else>{{ product.coins.amount }}</span>
        </div>
      </div>
      <!--      <div class="product&#45;&#45;desc" v-if="showDesc && product.description">-->
      <!--        {{ product.description }}-->
      <!--      </div>-->
    </div>
    <div class="bg--part">
      <img :src="product.membership.logo" class="bottom--icon" v-if="product.membership.logo" />
    </div>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    product: {
      default: null,
      require: true,
    },
    clickable: {
      default: false,
    },
    showDesc: {
      default: true,
    },
  },
  data: () => ({}),
  computed: {
    selectedProduct: {
      get() {
        return this.$store.state.product.selectedProduct;
      },
      set(val) {
        this.$store.commit('product/SET_SELECTED_PRODUCT', val);
      },
    },
  },
  methods: {
    selectProduct() {
      this.selectedProduct = this.product;
    },
  },
};
</script>
